import { A } from "@solidjs/router";
import { For } from "solid-js";
import {
  appHomepageScreenshot,
  appleLogo,
  checkIconBlue,
  playstoreLogo,
} from "~/assets/assets";
import { ButtonRegular } from "~/widgets/button";
import { Spacer } from "~/widgets/spacer";

export function DownloadAppLarge() {
  return (
    <div class="bg-red rounded-2xl border border-solid	border-[#DDD] p-1">
      <div class="rounded-xl border border-solid border-[#EDEEF1] bg-gradient-to-b from-[#EDEEF1] from-0%  to-[#FFF] to-100%	p-4">
        <div class="flex flex-col items-center">
          <span class="w-full text-left text-normal font-semibold	">
            Download app to view
            <br /> transactions details & invoice
          </span>
          <Spacer height={12} />
          <div class="relative  flex h-60  w-full justify-center rounded-xl border border-[#DDD] bg-white px-4">
            <img
              src={appHomepageScreenshot}
              class="absolute bottom-0 h-[227px] "
              alt="Hubble app screenshot"
            />
          </div>
          <Spacer height={12} />
          <AppBenefits />
          <Spacer height={12} />
          <A
            href="https://get.hbbl.in/U4tA/dwebhomeheader2?af_qr="
            class="w-full"
          >
            <ButtonRegular
              height="42px"
              isEnabled={true}
              onClick={() => {}}
              class=" w-full"
            >
              Download app
            </ButtonRegular>
          </A>
          <Spacer height={12} />
          <StoreIcons />
        </div>
      </div>
    </div>
  );
}

export function DownloadAppSmall() {
  return (
    <div class="bg-red rounded-2xl border border-solid	border-[#DDD] p-1">
      <div class="rounded-xl border border-solid border-[#EDEEF1] bg-gradient-to-b from-[#EDEEF1] from-0%  to-[#FFF] to-100%	p-4">
        <div class="flex flex-col items-center">
          <span class="w-full text-left text-normal font-semibold	">
            Download app to view
            <br /> transactions details & invoice
          </span>
          <Spacer height={12} />
          <div class="flex w-full flex-row">
            <A
              href="https://get.hbbl.in/U4tA/dwebhomeheader2?af_qr="
              class="flex-1"
            >
              <ButtonRegular
                height="42px"
                isEnabled={true}
                onClick={() => {}}
                class="w-full"
              >
                Download app
              </ButtonRegular>
            </A>
            <Spacer width={12} />
            <StoreIcons />
          </div>
        </div>
      </div>
    </div>
  );
}

function StoreIcons() {
  return (
    <div class="flex flex-row items-center">
      <A href="https://play.google.com/store/apps/details?id=money.myhubble&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img height={24} width={24} src={playstoreLogo} alt="Playstore" />
      </A>
      <Spacer width={8} />
      <A href="https://apps.apple.com/us/app/hubble-shopping-discounts-app/id1614427933?itsct=apps_box_badge&itscg=30200">
        <img height={24} width={24} src={appleLogo} alt="Appstore" />
      </A>
    </div>
  );
}

function AppBenefits() {
  const pointers = [
    "More brands & discounts",
    "Exclusive offers",
    "Transaction history & invoice",
  ];

  return (
    <div class="flex w-full flex-col">
      <For each={pointers}>
        {(pointer) => (
          <div class="flex flex-row pb-1">
            <img class="mr-1 h-5 w-5" src={checkIconBlue} alt="blue check" />
            <span class="text-medium">{pointer}</span>
          </div>
        )}
      </For>
    </div>
  );
}
