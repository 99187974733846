"use server";

import {
  getCoinsSummary,
  getLastNVouchers,
  getMinimalClientConfig,
  getUserProfile,
} from "../apis/server_apis";
import { CoinsSummaryResponse, VoucherDetails } from "~/server/types/order";
import { UserProfile } from "~/server/types/user";
import { Mode } from "~/types";
import { getRequestEvent } from "solid-js/web";
import { ClientConfig } from "../types/client";
import { ClientMinimalConfigOld } from "../types/brand";

export type ProfileRouteData = {
  isLoggedIn: boolean;
  userProfile: UserProfile;
  vouchers: VoucherDetails[];
  mode: Mode;
  clientConfig?: ClientMinimalConfigOld;
  coinsSummary?: CoinsSummaryResponse;
};

export const getProfileRouteData = async (): Promise<ProfileRouteData> => {
  const vouchers$P = getLastNVouchers(10);
  const userProfile$P = getUserProfile();
  let clientConfig$P: Promise<ClientMinimalConfigOld> | undefined;
  const requestEvent = getRequestEvent();
  let mode: Mode = "hubble";
  let clientId: string | undefined;
  let coinsSummary$P: Promise<CoinsSummaryResponse> | undefined;

  if (requestEvent?.nativeEvent) {
    mode = (requestEvent.locals.mode ?? "hubble") as Mode;
    if (mode === "rnr") {
      clientId = requestEvent.locals.clientId;
      coinsSummary$P = getCoinsSummary();
    } else if (mode === "hubble") {
      coinsSummary$P = getCoinsSummary();
    }
  }

  if (clientId) {
    clientConfig$P = getMinimalClientConfig(
      getRequestEvent()?.locals.clientId!
    );
  }

  const resolved = await Promise.all([
    vouchers$P,
    userProfile$P,
    clientConfig$P ?? Promise.resolve(undefined),
    coinsSummary$P ?? Promise.resolve(undefined),
  ]);

  let clientConfig = resolved[2];

  if (clientConfig) {
    return {
      isLoggedIn: true,
      vouchers: resolved[0],
      userProfile: resolved[1],
      mode: "rnr",
      clientConfig: clientConfig,
      coinsSummary: resolved[3],
    };
  } else {
    return {
      isLoggedIn: true,
      vouchers: resolved[0],
      userProfile: resolved[1],
      mode: "hubble",
      coinsSummary: resolved[3],
    };
  }
};
